"use client";

import * as React from "react";
import ReactPlayer from "react-player/youtube";
import { cn } from "~/lib/utils";
import { useHydrated } from "~/lib/hooks";
import { type Post } from "~/components/domain/post";
interface PostBodyVideoProps {
  className?: string;
  post: Post & {
    type: "video";
  };
}
export const PostBodyVideo: React.FC<PostBodyVideoProps> = ({
  className,
  post
}) => {
  const isHydrated = useHydrated();
  if (!isHydrated) {
    return <div className={cn([`aspect-video h-auto w-full overflow-hidden`, className])}></div>;
  }
  return <div className={cn(["w-full overflow-hidden", className])} data-sentry-component="PostBodyVideo" data-sentry-source-file="post-body-video.tsx">
      {post.video && <ReactPlayer url={post.video.url} controls={true} className="!aspect-video !h-auto !w-full" style={{
      maxWidth: "100%"
    }} />}
    </div>;
};