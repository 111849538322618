"use client";

import { type FC, useState, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { cn } from "~/lib/utils";
export interface EditablePropertyProps {
  className?: (isActive: boolean) => string;
  children?: (isActive: boolean) => React.ReactNode;
}
export const EditableProperty: FC<EditablePropertyProps> = ({
  children,
  className
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, handleClickOutside);
  const [editMode, setEditMode] = useState<boolean>(false);
  function handleClickOutside() {
    setEditMode(false);
  }
  return <div ref={ref} className={cn(["flex shrink grow flex-row items-start justify-start transition-all hover:cursor-text", typeof className === "string" ? className : className?.(editMode)])} onClick={() => {
    if (editMode) {
      return;
    }
    setEditMode(true);
  }} data-sentry-component="EditableProperty" data-sentry-source-file="editable-property.tsx">
      {children && <>{children(editMode)}</>}
    </div>;
};