"use client";

import { type PropsWithChildren, useMemo, useState, type FC } from "react";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Popover, PopoverTrigger, PopoverContent } from "~/components/ui/popover";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "~/components/ui/card";
import { hi2IconSet, type IconSelectItem } from "./icon-set";
export interface IconSelectProps extends PropsWithChildren {
  value: string | null; // the id of a valid icon
  onChange?: (iconId: string) => void;
  asChild?: boolean;
}
export const IconSelect: FC<IconSelectProps> = ({
  value,
  onChange,
  asChild,
  children
}) => {
  const [_selectedIcon, setSelectedIcon] = useState<IconSelectItem | null>(hi2IconSet.find(icon => icon.id === value) ?? null);
  const [query, setQuery] = useState("");
  const filteredIconList = useMemo(() => {
    return hi2IconSet.filter(icon => icon.id.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
  }, [query]);
  const handleIconSelect = (icon: IconSelectItem) => {
    setSelectedIcon(icon);
    if (onChange) {
      onChange(icon.id);
    }
  };
  return <Popover data-sentry-element="Popover" data-sentry-component="IconSelect" data-sentry-source-file="icon-select.tsx">
      <PopoverTrigger asChild={asChild} data-sentry-element="PopoverTrigger" data-sentry-source-file="icon-select.tsx">{children}</PopoverTrigger>
      <PopoverContent align="start" side="bottom" className="overflow-y-scroll p-0" data-sentry-element="PopoverContent" data-sentry-source-file="icon-select.tsx">
        <Card className="border-0 shadow-none" data-sentry-element="Card" data-sentry-source-file="icon-select.tsx">
          <CardHeader className="h-0 border-none p-0" data-sentry-element="CardHeader" data-sentry-source-file="icon-select.tsx">
            <CardTitle className="sr-only" data-sentry-element="CardTitle" data-sentry-source-file="icon-select.tsx">Icon Picker</CardTitle>
            <CardDescription className="sr-only" data-sentry-element="CardDescription" data-sentry-source-file="icon-select.tsx">
              Wählen Sie ein Icon aus der Liste
            </CardDescription>
          </CardHeader>
          <CardContent className="px-0 py-4" data-sentry-element="CardContent" data-sentry-source-file="icon-select.tsx">
            <div className="px-4">
              <Input id="search" placeholder="Icon suchen" className="mb-4 h-8" value={query} onChange={e => {
              setQuery(e.currentTarget.value);
            }} data-sentry-element="Input" data-sentry-source-file="icon-select.tsx" />
            </div>
            <div className="grid max-h-[240px] grid-cols-6 gap-2 overflow-y-scroll px-4">
              {filteredIconList.map(({
              id,
              icon
            }) => {
              const Icon = icon;
              return <Button key={id} title={id} onClick={() => handleIconSelect({
                id,
                icon
              })} variant="ghost" size="icon" className="text-foreground/60 hover:text-foreground">
                    <Icon className="h-5 w-5" />
                    <span className="sr-only text-xs">{id}</span>
                  </Button>;
            })}
            </div>
          </CardContent>
        </Card>
      </PopoverContent>
    </Popover>;
};