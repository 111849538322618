import React from "react";
import Image from "next/image";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "~/components/ui/carousel";
import { PostBodyText, type PostWithOrganization } from "~/components/domain/post";
import { OrganizationLogo } from "../../organization/organization-logo";
interface PostImageDetailViewProps {
  post: PostWithOrganization;
}
export const PostImageDetailView = ({
  post
}: PostImageDetailViewProps) => {
  const files = React.useMemo(() => {
    if (!post.fileList) {
      return [];
    }
    return post.fileList.sort((a, b) => a.id < b.id ? -1 : 1);
  }, [post.fileList]);
  return <div className="max-w-screen grid max-h-full w-screen grid-cols-1 overflow-y-hidden md:max-w-[1024px] md:grid-cols-5 lg:w-[1024px]" data-sentry-component="PostImageDetailView" data-sentry-source-file="post-with-image-view.tsx">
      {files.length === 1 && <div className="col-span-3 flex h-full max-h-full grow flex-col items-center justify-center overflow-hidden bg-black">
          <Image width={700} height={700} src={files[0]!.url} alt="Photo des Posts" className="h-full max-h-full w-full max-w-full object-contain" />
        </div>}

      {files.length > 1 && <Carousel className="col-span-3 flex h-full max-h-full grow flex-col items-center justify-center overflow-hidden bg-black">
          <CarouselContent className="-ml-2 h-full max-h-full w-full max-w-full">
            {files.map(file => <CarouselItem key={file.id} className="h-full max-h-full w-full max-w-full">
                <Image width={700} height={700} src={file.url} alt="Photo des Posts" className="h-full max-h-full w-full max-w-full object-contain" />
              </CarouselItem>)}
          </CarouselContent>
          <CarouselPrevious className="absolute left-4" />
          <CarouselNext className="absolute right-4" />
        </Carousel>}

      <div className="item-start col-span-2 flex h-fit max-h-full min-h-full flex-col justify-start gap-4 overflow-y-scroll p-4">
        <div className="flex w-full flex-row items-start justify-between pr-14">
          <div className="flex shrink-0 flex-row gap-2">
            <OrganizationLogo organization={post.organization} size="sm" className="aspect-square rounded-sm border border-[white]" data-sentry-element="OrganizationLogo" data-sentry-source-file="post-with-image-view.tsx" />
            <div className="flex h-9 flex-col items-start justify-start overflow-y-visible">
              <div className="text-sm font-semibold">
                {post.organization.name}
              </div>
              <div className="text-xs">
                {post.createdAt.toLocaleDateString("de-DE", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
              })}
              </div>
            </div>
          </div>
          <div className="flex grow"></div>
          <div className="flex flex-row items-start justify-end gap-2">
            {/* Todo: Add button to report */}
          </div>
        </div>

        <PostBodyText post={post} autoExpand={true} className="mt-4 px-0" data-sentry-element="PostBodyText" data-sentry-source-file="post-with-image-view.tsx" />
      </div>
    </div>;
};
export const PostImageDetailLoading = () => {
  return <div className="max-w-screen grid w-screen grid-cols-1 md:max-w-[1024px] md:grid-cols-5 lg:w-[1024px]" data-sentry-component="PostImageDetailLoading" data-sentry-source-file="post-with-image-view.tsx">
      <div className="col-span-3 flex h-full max-h-full grow flex-col items-center justify-center bg-black"></div>

      <div className="col-span-1">
        <span className="hidden md:flex"></span>
      </div>
    </div>;
};