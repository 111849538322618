"use client";

import React from "react";
import { X } from "lucide-react";
import { Button } from "~/components/ui/button";
import { Dialog, DialogContentWithoutCloseButton, DialogDescription, DialogTitle } from "~/components/ui/dialog";
import { type PostWithOrganization } from "~/components/domain/post";
import { PostImageDetailLoading, PostImageDetailView } from "~/components/domain/post/post-with-image";
export interface PostWithImageDialogProps {
  post: PostWithOrganization | null;
  autoOpen?: boolean;
  shouldClose?: () => boolean;
}
export default function PostWithImageDialog({
  post,
  autoOpen = false,
  shouldClose = () => true
}: PostWithImageDialogProps) {
  const [open, setOpen] = React.useState(autoOpen);
  function handleClose() {
    if (!shouldClose()) {
      return;
    }
    setOpen(false);
  }
  return <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-component="PostWithImageDialog" data-sentry-source-file="post-with-image-dialog.tsx">
      <DialogContentWithoutCloseButton onEscapeKeyDown={(e: KeyboardEvent) => {
      e.preventDefault();
      handleClose();
    }} onPointerDownOutside={(e: CustomEvent<{
      originalEvent: PointerEvent;
    }>) => {
      e.preventDefault();
      handleClose();
    }} className="rounded-xs w-content top-0 h-dvh max-w-fit translate-y-0 overflow-y-scroll border-none p-0 sm:top-10 sm:max-h-[80dvh]" data-sentry-element="DialogContentWithoutCloseButton" data-sentry-source-file="post-with-image-dialog.tsx">
        <DialogTitle className="sr-only" data-sentry-element="DialogTitle" data-sentry-source-file="post-with-image-dialog.tsx">
          Ein auf dem Climathub geteilter Post.
        </DialogTitle>
        <DialogDescription className="sr-only" data-sentry-element="DialogDescription" data-sentry-source-file="post-with-image-dialog.tsx">
          Ein auf dem Climathub geteilter Post.
        </DialogDescription>

        {!post && <PostImageDetailLoading />}
        {post && post.type === "image" && <PostImageDetailView post={{
        ...post,
        type: "image"
      }} />}

        <Button variant="ghost" size="icon" className="absolute right-4 top-4 rounded-full border-[0.5px] bg-background" onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="post-with-image-dialog.tsx">
          <X className="h-4 w-4" data-sentry-element="X" data-sentry-source-file="post-with-image-dialog.tsx" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogContentWithoutCloseButton>
    </Dialog>;
}