"use client";

import { useRouter } from "next/navigation";
import { toast } from "sonner";
import { api } from "~/trpc/react";
import { type BaseWidget } from "../../model/widget-model";
type PublishedStatusType = "DRAFT" | "PUBLISHED";
interface UpdateWidgetProps extends Omit<BaseWidget, "organizationId" | "createdAt" | "updatedAt" | "publishedStatus"> {
  publishedStatus?: PublishedStatusType | undefined;
}
export type UpdateWidgetMutationType = ReturnType<typeof api.widget.updateWidget.useMutation>;
export type UpdateWidgetType = (props: UpdateWidgetProps) => Promise<BaseWidget | null>;
export const useUpdateWidget = () => {
  const router = useRouter();
  const updateWidgetMutation = api.widget.updateWidget.useMutation();
  const updateWidget = async (props: UpdateWidgetProps): Promise<BaseWidget | null> => {
    const result = await updateWidgetMutation.mutateAsync({
      ...props
    });
    if (!result) {
      toast.error("Leider ist ein Fehler aufgetreten. Die Seitenkonfiguration konnte nicht aktualisiert werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      return null;
    }
    router.refresh();
    return result;
  };
  return {
    updateWidgetMutation,
    updateWidget
  };
};