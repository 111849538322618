import { type FC } from "react";
import { hi2IconSet } from "./icon-set";
export interface IconViewProps {
  id: string | null; // the id of a valid icon
  className?: string;
}
export const IconView: FC<IconViewProps> = ({
  id,
  className
}) => {
  const iconConfig = hi2IconSet.find(icon => icon.id === id) ?? null;
  if (!id) {
    return null;
  }
  if (!iconConfig) {
    console.error(`IconView: Could not find icon with id ${id}`);
    return null;
  }
  const Icon = iconConfig.icon;
  return <Icon className={className} title={id} data-sentry-element="Icon" data-sentry-component="IconView" data-sentry-source-file="icon-view.tsx" />;
};