"use client";

import { useRouter } from "next/navigation";
import { toast } from "sonner";
import { api } from "~/trpc/react";
import { type BaseWidget } from "../../model/widget-model";
interface DeleteWidgetProps {
  id: string;
}
export type DeleteWidgetMutationType = ReturnType<typeof api.widget.deleteWidget.useMutation>;
export type DeleteWidgetType = (props?: DeleteWidgetProps) => Promise<BaseWidget | null>;
export const useDeleteWidget = () => {
  const router = useRouter();
  const deleteWidgetMutation = api.widget.deleteWidget.useMutation();
  const deleteWidget = async (props: DeleteWidgetProps) => {
    const {
      id
    } = props;
    const result = await deleteWidgetMutation.mutateAsync({
      id: id
    });
    if (!result) {
      toast.error("Leider ist ein Fehler aufgetreten. Das Widget konnte nicht gelöscht werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      return null;
    }
    router.refresh();
    return result;
  };
  return {
    deleteWidgetMutation,
    deleteWidget
  };
};