import { type FC } from "react";
import Image from "next/image";
import { cn } from "~/lib/utils";
import { type Organization } from "./model/organization-model";
const __INTERNAL_sizes = {
  // Note: logos look to pixelated when rendered on correct size; Let's double it
  sm: {
    width: 72,
    height: 72,
    tw: "h-9 w-9"
  },
  md: {
    width: 80,
    height: 80,
    tw: "h-10 w-10"
  },
  lg: {
    width: 128,
    height: 128,
    tw: "h-16 w-16"
  },
  xl: {
    width: 192,
    height: 192,
    tw: "h-24 w-24"
  }
};
export interface OrganizationLogoProps {
  organization: Organization;
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
}
export const OrganizationLogo: FC<OrganizationLogoProps> = ({
  organization,
  className,
  size = "md"
}) => {
  const {
    width,
    height,
    tw
  } = __INTERNAL_sizes[size];
  if (!organization.logoUrl) {
    return <div className={cn(["h-9 w-9 rounded-sm border bg-muted", tw, className])}></div>;
  }
  return <Image src={organization.logoUrl} alt={`Logo ${organization.name}`} width={width} // w-9 = 36px
  height={height} // h-9 = 36px
  quality={100} className={cn(["border-[white]d h-9 w-9 rounded-sm border", tw, className])} data-sentry-element="Image" data-sentry-component="OrganizationLogo" data-sentry-source-file="organization-logo.tsx" />;
};