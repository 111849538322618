import Image from "next/image";
import * as React from "react";
import { type Post } from "~/components/domain/post";
interface PostBodyImageProps {
  post: Post & {
    type: "image";
  };
}
export const PostBodyImage: React.FC<PostBodyImageProps> = ({
  post
}) => {
  const files = React.useMemo(() => {
    if (!post.fileList) {
      return [];
    }
    return post.fileList.sort((a, b) => a.id < b.id ? -1 : 1);
  }, [post.fileList]);
  return <>
      {files.length === 1 && files[0] && <div className="w-full">
          <Image width={700} height={700} src={files[0].url} alt="Vorschau des Bildes des Posts" className="mx-auto max-h-[500px] w-min rounded-sm object-contain" />
        </div>}

      {files.length === 2 && <div className="grid grid-cols-2 gap-1">
          {files.map((file, idx) => <Image width={700} height={700} key={idx} src={file.url} alt="Vorschau des Bildes des Posts" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />)}
        </div>}
      {files.length === 3 && <div className="grid grid-cols-3 gap-1">
          <Image width={700} height={700} src={files[0]!.url} alt="Vorschau des Bildes des Posts" className="col-span-2 mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
          <div className="col-span-1 flex flex-col items-center justify-center gap-1">
            <Image width={700} height={700} src={files[1]!.url} alt="Vorschau des Bildes des Posts" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
            <Image width={700} height={700} src={files[2]!.url} alt="Vorschau des Bildes des Posts" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
          </div>
        </div>}

      {files.length === 4 && <div className="grid grid-cols-3 gap-1">
          <Image width={700} height={700} src={files[0]!.url} alt="Vorschau des Bildes des Posts" className="col-span-2 mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
          <div className="col-span-1 flex h-min max-h-[500px] flex-col items-center justify-center gap-1 overflow-y-hidden">
            <Image width={700} height={700} src={files[1]!.url} alt="Vorschau des Bildes des Posts" className="mx-auto w-min shrink grow rounded-sm object-cover object-center" />
            <Image width={700} height={700} src={files[2]!.url} alt="Vorschau des Bildes des Posts" className="mx-auto w-min shrink grow rounded-sm object-cover object-center" />
            <Image width={700} height={700} src={files[3]!.url} alt="Vorschau des Bildes des Posts" className="mx-auto w-min shrink grow rounded-sm object-cover object-center" />
          </div>
        </div>}

      {files.length > 4 && <div className="grid grid-cols-3 gap-1">
          <Image width={700} height={700} src={files[0]!.url} alt="Vorschau des Bildes des Posts" className="col-span-2 mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
          <div className="col-span-1 flex flex-col items-center justify-center gap-1">
            <Image width={700} height={700} src={files[1]!.url} alt="Vorschau des Bildes des Posts" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
            <Image width={700} height={700} src={files[2]!.url} alt="Vorschau des Bildes des Posts" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
            <div className="relative h-1/3 w-full">
              <div className="absolute inset-0 flex flex-row items-center justify-center rounded-sm bg-black/70 text-4xl font-semibold text-white">
                {`+ ${files.length - 3} `}
              </div>
              <Image width={700} height={700} src={files[3]!.url} alt="Vorschau des Bildes des Posts" className="mx-auto h-full max-h-[500px] w-min rounded-sm object-cover object-center" />
            </div>
          </div>
        </div>}
    </>;
};