"use client";

import * as React from "react";
import { cn } from "~/lib/utils";
import { HiArrowSmallDown } from "react-icons/hi2";
import { Button } from "~/components/ui/button";
import { EditorContentView } from "~/components/ui/editor/editor-content-view";
import { type Post } from "~/components/domain/post";
interface PostBodyTextProps {
  post: Post;
  autoExpand?: boolean;
  className?: string;
}
export const PostBodyText: React.FC<PostBodyTextProps> = ({
  post,
  autoExpand = false,
  className
}) => {
  const editorContentViewRef = React.useRef<HTMLDivElement>(null);
  const [hasMoreText, setHasMoreText] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(autoExpand);
  React.useEffect(() => {
    if (!editorContentViewRef.current) {
      return;
    }
    const hasMoreText = editorContentViewRef.current.scrollHeight > editorContentViewRef.current.clientHeight;
    setHasMoreText(hasMoreText);
  }, [editorContentViewRef]);
  return <div className={cn(["relative pb-4 pl-4 pr-6", className])} data-sentry-component="PostBodyText" data-sentry-source-file="post-body-text.tsx">
      <EditorContentView ref={editorContentViewRef} content={post.content} className={cn(["overflow-hidden text-sm text-foreground/80", isExpanded ? "" : "line-clamp-4"])} data-sentry-element="EditorContentView" data-sentry-source-file="post-body-text.tsx" />
      {hasMoreText && !isExpanded && <div className={cn(["flex w-full flex-row items-center justify-center pt-2"])}>
          <Button variant="outline" size="sm" className="group rounded-full border-[0.5px] shadow-md" onClick={() => setIsExpanded(true)}>
            mehr anzeigen{" "}
            <HiArrowSmallDown className="ml-1 h-4 w-4 transition-transform duration-300 group-hover:translate-y-0.5" />
          </Button>
        </div>}
    </div>;
};